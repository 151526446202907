<template>
<div>
    <v-menu v-model="shower" :position-x="x" :position-y="y" absolute offset-y close-on-content-click close-on-click  transition="slide-y-transition">
      <v-list dense>
        <!-- <v-list-item-group v-model="item" color="primary"> -->
        <v-list-item v-for="(item, i) in items" :key="i" @click="goto(item.to)">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- </v-list-item-group> -->
      </v-list>
    </v-menu>
    <!-- <setpermissionfile
    :show="opensetPermissionFile"    
    :filedata="currentfile"       
    @closesetPermissionFile="opensetPermissionFile = false,$emit('openDrag')"
    @opensetPermissionFile="opensetPermissionFile = true"
    @closeDrag="$emit('closeDrag')"
    ></setpermissionfile> -->
</div>
</template>
<script>
import download from "downloadjs";
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
const setpermissionfile = () => import("@/components/optional/dialog-setpermissionfile");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
    props: ["show", "AreaX", "AreaY", "file", "parentfolder", "parentfoldername"],
  components: {
      setpermissionfile,
  },
   data: function() {
    return {
      listdowload:[],
      timeout: 60000,
      percentCompleted: 0,
      namedowload: "",
      opensetPermissionFile:false,
      currentfile:{},
      items: [
        {
          text: "ManageStorage",
          icon: "settings",
          to: "download",
          event: "fn"
        },
        // {
        //   text: "download",
        //   icon: "mdi-download",
        //   to: "download",
        //   event: "fn"
        // },
        // {
        //   text: "set permission",
        //   icon: "mdi-cogs",
        //   to: "setPermissionFile",
        //   event: "fn",
        // },
      ],
      // showMenu: false,
      x: 0,
      y: 0
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.showmenus();
          this.$emit('closeDrag');
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closecurrentonly");
        }
      }
    },
  },
   methods: {
    goto(parameter) {
      if (parameter === "ManageStorage") {
        // this.$emit('downloadfile');
        // console.log("download");       
        this.showMenu = false;
      } 
      // else if (parameter === "setPermissionFile") {         
      //   this.opensetPermissionFile = true;
      //   this.currentfile = this.file;
      // }
    },
    showmenus() {
      this.showMenu = false;
      this.x = this.AreaX;
      this.y = this.AreaY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    closeDialog() {
      this.openinboxdetail = false;
      this.$emit("loaddatafrommainright");
      
    }
  } 
}
</script>
<style>

</style>